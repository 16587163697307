<!-- Botão de menu (hambúrguer) para dispositivos móveis -->
<div class="menu-toggle" (click)="toggleSidebar()">☰</div>

<div class="sidebar" [ngClass]="{ active: isSidebarActive }">
  <!-- <div class="sidebar-header">
    <img src="assets/img/logo-projecao-profissional.svg" alt="Logo" class="logo" />
  </div> -->

  <div class="menu-item" routerLink="/home" routerLinkActive="active">
    <i class="fas fa-home"></i>
    <span>Página Inicial</span>
  </div>

  <div class="menu-item" routerLink="/employees" routerLinkActive="active" *ngIf="isAdmin || isManager || isSupervisor">
    <i class="fas fa-users"></i>
    <span>Funcionários</span>
  </div>

  <div
    class="menu-item"
    routerLink="/professionals"
    routerLinkActive="active"
    *ngIf="isAdmin || isManager || isSupervisor"
  >
    <i class="fas fa-user-tie"></i>
    <span>Profissionais</span>
  </div>

  <div class="menu-item" routerLink="/suppliers" routerLinkActive="active" *ngIf="isAdmin || isManager || isSupervisor">
    <i class="fas fa-truck-field"></i>
    <span>Fornecedores</span>
  </div>

  <div class="menu-item" routerLink="/responsibilities" routerLinkActive="active" *ngIf="isAdmin">
    <i class="fas fa-suitcase"></i>
    <span>Cargos</span>
  </div>

  <div class="menu-item" routerLink="/routines" routerLinkActive="active" *ngIf="isAdmin">
    <i class="fas fa-list-check"></i>
    <span>Rotinas</span>
  </div>

  <div class="menu-item" routerLink="/tasks" routerLinkActive="active" *ngIf="isManager || isSupervisor">
    <i class="fas fa-list-check"></i>
    <span>Tarefas</span>
  </div>

  <div class="menu-item" routerLink="/goals" routerLinkActive="active" *ngIf="isManager || isSupervisor">
    <i class="fas fa-bullseye"></i>
    <span>Metas</span>
  </div>

  <div class="menu-item" routerLink="/routines" routerLinkActive="active" *ngIf="isUser">
    <i class="fas fa-briefcase"></i>
    <span>Minhas Rotinas</span>
  </div>
  <div class="menu-item" routerLink="/goals" routerLinkActive="active" *ngIf="isUser">
    <i class="fas fa-bullseye"></i>
    <span>Minhas Metas</span>
  </div>
  <div class="menu-item" routerLink="/tasks" routerLinkActive="active" *ngIf="isUser">
    <i class="fas fa-list-check"></i>
    <span>Minhas Tarefas</span>
  </div>

  <div class="menu-item" routerLink="/documents" routerLinkActive="active" *ngIf="isManager || isSupervisor || isUser">
    <i class="fas fa-folder-open"></i>
    <span>Documentos</span>
  </div>

  <div class="menu-item" routerLink="/reports" routerLinkActive="active" *ngIf="isManager || isSupervisor">
    <i class="fas fa-chart-pie"></i>
    <span>Relatórios</span>
  </div>

  <div
    class="menu-item"
    routerLink="/change-password"
    routerLinkActive="active"
    *ngIf="isManager || isSupervisor || isUser"
  >
    <i class="fas fa-user-gear"></i>
    <span>Configurações</span>
  </div>

  <div class="menu-item" routerLink="/chat" routerLinkActive="active" *ngIf="isManager || isSupervisor || isUser">
    <i class="fas fa-comments"></i>
    <span>Chat</span>
  </div>

  <div class="sidebar-footer">
    <div *ngIf="!this.isAdmin">
      <img [src]="personPicture" alt="photo" class="img-thumbnail" width="40" height="40" />
      <br />
      <span>Usuário: {{ personName }}</span>
    </div>
    <span>Permissão: {{ personRole }}</span>
    <div class="current-time">🕑 Horário: {{ currentTime }}</div>
    <button class="logout-btn" (click)="logout()">Sair</button>
  </div>
</div>

<!-- Modal para alterar as Empresas -->
<div class="floating-btn" *ngIf="isAdmin || isManager || isSupervisor">
  <div class="btn-group dropup">
    <button type="button" class="btn btn-primary dropdown-toggle" (click)="toggleDropdown()">
      {{ firstOffice.fantasyName }}
    </button>
    <ul class="dropdown-menu dropdown-menu-center custom-dropup" [class.show]="isDropdownOpen">
      <li *ngFor="let company of officeResponses">
        <a class="dropdown-item text-center" (click)="changeCurrentOffice(company)">{{ company.fantasyName }}</a>
      </li>
    </ul>
  </div>
</div>

<!-- Botão Flutuante de Homologacão -->
<div *ngIf="isStaging" class="floating-homolog">
  <span>Homologação</span>
</div>

<!-- Botão Flutuante de Chat -->
<!-- <div class="floating-chat-btn">
  <button
    type="button"
    class="btn btn-primary"
    (click)="toggleChat()"
  >
    <i class="bi bi-chat"></i>
  </button>
</div> -->

<!-- Janela Lateral de Chat -->
<!-- <div class="chat-window" [class.active]="chatOpen">
  <div class="chat-header">
    <h5>Chat</h5>
    <button type="button" class="btn-close" (click)="toggleChat()"></button>
  </div>
  <div class="chat-body">
    <div *ngFor="let msg of messages" [ngClass]="{'sent': msg.sent, 'received': !msg.sent}">
      <strong *ngIf="!msg.sent">Supervisor:</strong>
      <strong *ngIf="msg.sent">Usuário:</strong>
      <br/>
      {{ msg.content }}
    </div>
  </div>
  <div class="chat-footer">
    <input type="text" [(ngModel)]="newMessage" placeholder="Digite sua mensagem..." class="form-control" (keydown.enter)="sendMessage()"/>
  </div>
</div> -->

<div class="content-wrapper">
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>
