<header class="site-header">
  <div class="logo">
    <img src="assets/img/logo-projecao-profissional.svg" (click)="goToLandingPage()" alt="Logo da Empresa" />
  </div>
  <nav class="navigation">
    <ul>
      <li><a (click)="goToSignup()" class="signup-link">Cadastro / Planos</a></li>
      <li><button (click)="goToLogin()" class="btn login-btn">Login</button></li>
    </ul>
  </nav>
</header>
