<div class="reset-password-container">
  <h2>Redefinir Senha</h2>
  <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
    <div>
      <label for="newPassword">Nova Senha</label>
      <input type="password" id="newPassword" formControlName="newPassword" />
      <div *ngIf="resetForm.get('newPassword').invalid && resetForm.get('newPassword').touched">
        <small *ngIf="resetForm.get('newPassword').errors.required">Senha é obrigatória.</small>
        <small *ngIf="resetForm.get('newPassword').errors.minlength">Senha deve ter no mínimo 6 caracteres.</small>
      </div>
    </div>

    <div>
      <label for="confirmPassword">Confirme a Nova Senha</label>
      <input type="password" id="confirmPassword" formControlName="confirmPassword" />
      <div *ngIf="resetForm.errors?.mismatch && resetForm.get('confirmPassword').touched">
        <small>As senhas não coincidem.</small>
      </div>
    </div>

    <button type="submit" [disabled]="resetForm.invalid">Redefinir Senha</button>
  </form>
</div>
