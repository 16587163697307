<footer class="site-footer">
  <div class="footer-content">
    <div class="company-info">
      <h3>Sistema Mérito</h3>
      <p>Endereço: Rua Exemplo, 123, Bairro, Cidade - Estado</p>
      <p>Email: contato&#64;sistemamerito.com.br</p>
      <p>Telefone: (11) 1234-5678</p>
    </div>
    <div class="useful-links">
      <h4>Links Úteis</h4>
      <ul>
        <li><a href="#">Sobre Nós</a></li>
        <li><a href="#">Política de Privacidade</a></li>
        <li><a href="#">Termos de Uso</a></li>
        <li><a href="#">Suporte</a></li>
      </ul>
    </div>
    <div class="social-media">
      <h4>Siga-nos</h4>
      <ul>
        <li>
          <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
        </li>
        <li>
          <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
        </li>
        <li>
          <a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a>
        </li>
        <li>
          <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
        </li>
      </ul>
    </div>
  </div>
  <div class="footer-bottom">
    <p>&copy; {{ currentYear }} Sistema Mérito. Todos os direitos reservados.</p>
  </div>
</footer>
