<div class="first-access-container">
  <h1 class="title">Defina sua Nova Senha</h1>
  <p class="subtitle">Para continuar, crie sua nova senha.</p>

  <form (ngSubmit)="savePassword()">
    <div class="form-group password-group">
      <label for="newPassword">Nova Senha</label>
      <div class="password-input-wrapper">
        <input
          [type]="hidePassword ? 'password' : 'text'"
          id="newPassword"
          name="newPassword"
          [(ngModel)]="newPassword"
          required
          [disabled]="isLoading"
        />
        <button type="button" class="toggle-password" (click)="togglePasswordVisibility()">
          {{ hidePassword ? "Mostrar" : "Esconder" }}
        </button>
      </div>
    </div>

    <div class="form-group password-group">
      <label for="confirmPassword">Confirmar Nova Senha</label>
      <div class="password-input-wrapper">
        <input
          [type]="hideNewPassword ? 'password' : 'text'"
          id="confirmPassword"
          name="confirmPassword"
          [(ngModel)]="confirmPassword"
          required
          [disabled]="isLoading"
        />
        <button type="button" class="toggle-password" (click)="toggleNewPasswordVisibility()">
          {{ hideNewPassword ? "Mostrar" : "Esconder" }}
        </button>
      </div>
    </div>

    <div class="error" *ngIf="errorMessage">{{ errorMessage }}</div>

    <button type="submit" class="btn primary" [disabled]="isLoading">
      {{ isLoading ? "Salvando..." : "Salvar Senha" }}
    </button>
  </form>
</div>
