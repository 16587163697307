<div class="container mt-4">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/documents">Documentos</a></li>
      <li class="breadcrumb-item active" aria-current="page">Cadastrar</li>
    </ol>
  </nav>

  <h1>Cadastro de Documentos</h1>

  <div id="fullPageContent" class="container my-5">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <section class="mb-4">
        <div class="row">
          <div class="col-md-6 mb-4">
            <label for="title" class="form-label">Título</label>
            <input
              type="text"
              id="title"
              formControlName="title"
              class="form-control"
              [ngClass]="{
                'is-invalid': formGroup.get('title')?.invalid && formGroup.get('title')?.touched,
                'is-valid': formGroup.get('title')?.valid && formGroup.get('title')?.touched
              }"
            />
            <div *ngIf="formGroup.get('title')?.invalid && formGroup.get('title')?.touched" class="invalid-feedback">
              O título é obrigatório.
            </div>
          </div>
          <div class="col-md-6 mb-4">
            <label for="responsibilityId" class="form-label">Cargo</label>
            <select
              id="responsibilityId"
              class="form-select"
              formControlName="responsibilityId"
              (change)="onResponsibilityChange($event)"
              [ngClass]="{
                'is-invalid': formGroup.get('responsibilityId')?.invalid && formGroup.get('responsibilityId')?.touched,
                'is-valid': formGroup.get('responsibilityId')?.valid && formGroup.get('responsibilityId')?.touched
              }"
            >
              <option value="" disabled selected>Selecione o Cargo</option>
              <option *ngFor="let responsibility of responsibilities" [value]="responsibility['id']">
                {{ responsibility["name"] }}
              </option>
            </select>
            <div
              *ngIf="formGroup.get('responsibilityId')?.invalid && formGroup.get('responsibilityId')?.touched"
              class="invalid-feedback"
            >
              O cargo é obrigatório.
            </div>
          </div>

          <div class="col-md-6 mb-4">
            <label for="doc" class="form-label">Selecione o Documento</label>
            <input
              type="file"
              id="doc"
              formControlName="doc"
              class="form-control"
              (change)="onFileSelected($event)"
              [ngClass]="{
                'is-invalid': formGroup.get('doc')?.invalid && formGroup.get('doc')?.touched,
                'is-valid': formGroup.get('doc')?.valid && formGroup.get('doc')?.touched
              }"
            />
            <div *ngIf="formGroup.get('doc')?.invalid && formGroup.get('doc')?.touched" class="invalid-feedback">
              O documento é obrigatória.
            </div>
          </div>
          <div class="col-md-6 mb-4">
            <label for="type" class="form-label">Tipo do Documento</label>
            <select
              id="type"
              formControlName="type"
              class="form-select"
              [ngClass]="{
                'is-invalid': formGroup.get('type')?.invalid && formGroup.get('type')?.touched,
                'is-valid': formGroup.get('type')?.valid && formGroup.get('type')?.touched
              }"
            >
              <option value="" disabled selected>Selecione</option>
              <option value="conduct">Código e Ética de Conduta</option>
              <option value="manual">Manual Operacional</option>
            </select>
            <div *ngIf="formGroup.get('type')?.invalid && formGroup.get('type')?.touched" class="invalid-feedback">
              O tipo de documento é obrigatório.
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <div class="d-grid gap-2" style="width: 50%">
              <button type="submit" class="btn btn-primary" [disabled]="formGroup.invalid">Salvar</button>
            </div>
          </div>
        </div>
      </section>
    </form>
  </div>
</div>

<app-spinner [isLoading]="isSaving"></app-spinner>
