<nav class="nav-header">
  <!-- Logo à esquerda -->
  <div class="nav-left">
    <img src="assets/logo.png" alt="Logo do Sistema" class="logo" />
  </div>

  <!-- Informações do usuário e botão de logout à direita -->
  <div class="nav-right">
    <div class="user-info">
      <img [src]="user.photo" alt="Foto do Usuário" class="user-photo" />
      <span class="user-name">{{ user.name }}</span>
    </div>
    <button class="btn logout-btn" (click)="logout()">Sair</button>
  </div>
</nav>
