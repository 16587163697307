<div class="forgot-container">
  <h1 class="forgot-title">Recuperar Senha</h1>
  <p class="forgot-subtitle">Digite seu e-mail e enviaremos as instruções para redefinir sua senha.</p>
  <form (ngSubmit)="forgotPassword()">
    <div class="form-group">
      <label for="email">E-mail</label>
      <input type="email" id="email" [(ngModel)]="email" name="email" required [disabled]="isLoading" />
    </div>
    <button type="submit" class="btn primary" [disabled]="isLoading">
      {{ isLoading ? "Enviando..." : "Enviar Instruções" }}
    </button>
  </form>
  <div *ngIf="message" class="message">{{ message }}</div>
  <div *ngIf="errorMessage" class="error">{{ errorMessage }}</div>
  <a routerLink="/sign-in" class="back-to-login">Voltar para Login</a>
</div>
