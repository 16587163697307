<div class="login-container">
  <h1 class="login-title">Bem-vindo de Volta!</h1>
  <p class="login-subtitle">Acesse sua conta para gerenciar seus talentos</p>
  <form (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="username">Usuário</label>
      <input type="text" id="username" [(ngModel)]="username" name="username" required [disabled]="isLoading" />
    </div>
    <div class="form-group password-group">
      <label for="password">Senha</label>
      <div class="password-input-wrapper">
        <input
          [type]="hidePassword ? 'password' : 'text'"
          id="password"
          [(ngModel)]="password"
          name="password"
          required
          [disabled]="isLoading"
        />
        <button type="button" class="toggle-password" (click)="togglePasswordVisibility()">
          {{ hidePassword ? "Mostrar" : "Esconder" }}
        </button>
      </div>
    </div>
    <button type="submit" class="btn primary" [disabled]="isLoading">
      {{ isLoading ? "Entrando..." : "Entrar" }}
    </button>
  </form>
  <a routerLink="/forgot-password" class="forgot-password">Esqueceu a senha?</a>
</div>
